import { configureStore } from '@reduxjs/toolkit';
import auth from './auth';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCAbkZTmS6I6pXfOZQsRzsTb0E9Fd4DaEY",
    authDomain: "victories-gg.firebaseapp.com",
    projectId: "victories-gg",
    storageBucket: "victories-gg.appspot.com",
    messagingSenderId: "278383817760",
    appId: "1:278383817760:web:873487e4d66a2aff0af845",
    measurementId: "G-NR4MGD3X3L"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

const store = configureStore({
    reducer: { auth: auth.reducer },
});

// Can still subscribe to the store
store.subscribe(() => console.log(store.getState()));

getAuth().onAuthStateChanged(user => {
    if (user) {
        user.getIdToken().then(token => {
            store.dispatch(auth.actions.authReady({ userInfo: { name: user.displayName }, token }));
        });
    } else {
        store.dispatch(auth.actions.authReady({ userInfo: null, token: null }));
    }
});

export { store, auth };
