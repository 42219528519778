import axios from 'axios';
import { getAuth } from 'firebase/auth';

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/api/v1' : 'https://api.victories.gg/api/v1',
    timeout: 3000,
});

function get(url) {
    const user = getAuth().currentUser;

    if (user) {
        return user.getIdToken().then(token => instance.get(url, { headers: { authorization: "Bearer " + token } }));
    } else {
        console.log("No user.");
        return instance.get(url);
    }
}

function post(url, data) {
    const user = getAuth().currentUser;

    if (user) {
        return user.getIdToken().then(token => instance.post(url, data, { headers: { authorization: "Bearer " + token } }));
    } else {
        return instance.get(url);
    }
}

function deletee(url) {
    const user = getAuth().currentUser;

    if (user) {
        return user.getIdToken().then(token => instance.delete(url, { headers: { authorization: "Bearer " + token } }));
    } else {
        return instance.delete(url);
    }
}

export default { get, post, deletee };
