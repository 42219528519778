import axios from 'axios';
import he from 'he';
import { useEffect } from 'react';
import { useState } from 'react';
import { Container, InputGroup, ListGroup, DropdownButton, Dropdown, FormControl, Button, Spinner, Card, Row, Col, Form, Nav } from 'react-bootstrap';
import { BsCheckCircleFill, BsFillBookmarkPlusFill, BsPlusCircleFill, BsList, BsGrid, BsTrophyFill } from 'react-icons/bs';
import ReactTimeago from 'react-timeago';
import api from './api';
import { store, auth } from './store';

const Browse = ({ }) => {
    const [victories, setVictories] = useState([]);

    const fetchVictories = () => {
        api.get('/victories').then(r => setVictories(r.data));
    }

    useEffect(() => {
        fetchVictories();
        return null;
    }, [])

    const xpOptions = [
        { icon: '🥉', xp: 2 },
        { icon: '🥈', xp: 10 },
        { icon: '🥇', xp: 50 },
        { icon: '🏆', xp: 150 },
        { icon: '🏰', xp: 500 },
    ];

    const [currentOption, setOption] = useState(xpOptions[0]);
    const [description, setDescription] = useState('');
    const [submitting, setSubmitting] = useState(false);


    const submit = (description, xp, emoji) => {
        if (!store.getState().auth.userInfo) {
            store.dispatch(auth.actions.openLogin());
            return;
        }

        setSubmitting(true);
        api.post('/victories', { description, xp, emoji }).then(r => {
            setDescription('');
            setSubmitting(false);
            fetchVictories();
        });
    }

    if (!victories) {
        return (
            <Container className="p-lg-5 p-2 pt-3 mb-4 bg-light rounded-3">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    const sorted = victories.map(v => v.epoch).sort((a, b) => a - b);
    const lastModified = sorted.length ? sorted[0] : 0;

    return (<>
        <Container className="p-lg-5 p-2 pt-3 mb-4 bg-light rounded-3">
            <h1 className="header"><img src="/books-icon-48.png" style={{ marginRight: 8, height: 36, marginBottom: 8 }} /> Victories.gg</h1>
            <p style={{ textAlign: 'center' }}>Take a moment to focus on the good stuff you've achieved, <i>however small</i>.<br />After a little while, you can see your growth as a person!</p>

            <br />

            <Form style={{ maxWidth: 720, margin: '0 auto' }} onSubmit={x => { x.preventDefault(); x.stopPropagation(); submit(description, currentOption.xp, currentOption.icon) }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <InputGroup className="mb-0">
                        <Form.Control type="text"
                            placeholder="What victory, epic or miniscule, do you want to add?"
                            value={description} onChange={e => setDescription(e.target.value)} />

                        <DropdownButton variant="outline-success" title={`${currentOption.icon} +${currentOption.xp} XP`} align="end">
                            {xpOptions.map(opt => <Dropdown.Item href="#" onClick={() => setOption(opt)}>{opt.icon} <span style={{ float: 'right' }}>+{opt.xp} XP</span></Dropdown.Item>)}
                            <Dropdown.Divider />
                            <Dropdown.Item href="#">What's all this?</Dropdown.Item>
                        </DropdownButton>

                        <Button disabled={submitting} variant="success" type="submit">
                            <BsTrophyFill />
                        </Button>
                    </InputGroup>

                    <Form.Text className="text-muted">
                        Nobody can see this, just you.
                    </Form.Text>
                </Form.Group>
            </Form>
        </Container>

        <Container>
            <Row>
                <Col md={2}>
                    <Card>
                        <Card.Header>Categories <Button style={{ float: 'right', padding: 0 }} variant="text" id="button-addon2">
                            <BsPlusCircleFill />
                        </Button></Card.Header>

                        <Nav defaultActiveKey="/home" className="flex-column">
                            <Nav.Link href="/home" active style={{ backgroundColor: 'rgba(0,0,0,.03)' }}>General</Nav.Link>
                            {/* <Nav.Link eventKey="link-3">Studying</Nav.Link>
                            <Nav.Link eventKey="link-3">Work</Nav.Link>
                            <Nav.Link eventKey="link-1">Personal</Nav.Link>
                            <Nav.Link eventKey="link-2">Fitness</Nav.Link> */}
                        </Nav>
                    </Card>
                </Col>

                <Col md={10}>
                    <Card>
                        <Card.Header>
                            <DropdownButton variant="outline-secondary" title={<span><BsList /> List view</span>} style={{ float: 'right' }} size="sm">
                                <Dropdown.Item href="#" active><BsList /> List view</Dropdown.Item>
                                <Dropdown.Item href="#" disabled><BsGrid /> Grid view</Dropdown.Item>
                            </DropdownButton>

                            Victory List<br />
                            <span style={{ fontSize: 12 }} className="text-muted">You have 100 XP</span>
                        </Card.Header>

                        <ListGroup>
                            {victories.length ? victories.map(v => <ListGroup.Item>
                                <span style={{ marginRight: 8 }}>{v.emoji}</span>
                                <span>{v.description} <span className="text-muted" style={{ fontSize: 12, marginLeft: 8 }}><ReactTimeago date={v.epoch * 1000} /></span></span>
                                <span style={{ float: 'right' }} className="text-muted">+{v.xp} XP</span>
                            </ListGroup.Item>) : <ListGroup.Item>Nothing here yet! Why not log a recent, tiny victory?</ListGroup.Item>}
                        </ListGroup>

                        <Card.Footer>
                            <Card.Text className="text-muted" style={{ fontSize: 12 }}>This list was last modified <ReactTimeago date={lastModified * 1000} />.</Card.Text>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>);
}

export default Browse;
